import './App.css';


import './App.css';
import React,{Suspense} from "react" 
import { Route, Routes } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
const Login=React.lazy(()=> import('./components/Login'))

const Home=React.lazy(()=> import('./components/Home'))
const Signout=React.lazy(()=> import('./components/Signout'))
const Loginad=React.lazy(()=> import('./components/Loginad'))
const Listofpays=React.lazy(()=> import('./components/Listofpays'))
const Staff=React.lazy(()=> import('./components/Staff'))
const Stafflistmba=React.lazy(()=> import('./components/Stafflistmba'))
const AddStaff=React.lazy(()=> import('./components/AddStaff'))
const Receipt=React.lazy(()=> import('./components/Receipt'))

function App() {
  return (
    <div className="App">
    <Routes>
<Route  path="/"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Login /></Suspense>}/>



        <Route  path="/home"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Home /></Suspense>}/>

        
        <Route  path="/receipt/:id"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Receipt /></Suspense>}/>

        

        <Route  path="/signout"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Signout /></Suspense>}/>

        <Route  path="/Loginad"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Loginad /></Suspense>}/>


        <Route  path="/listofpays"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Listofpays /></Suspense>}/>


        <Route  path="/staff/:id"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Staff /></Suspense>}/>

        
        <Route  path="/stafflistmba"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><Stafflistmba /></Suspense>}/>


       
        <Route  path="/addStaff"  element={ <Suspense fallback={<div sx={{ width: '100%' }}>
    
    <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>} ><AddStaff /></Suspense>}/>

        
    </Routes>
    </div>
  );
}

export default App;
